import {useEffect, useState} from "react";
import Loading from "../../component/Loading";
import * as api from "../../api/api";

const FreeMatch = ({props, onCart}) => {
    const [loading, setLoading] = useState(false);
    const [contentsList, setContentsList] = useState({});
    const [expandedMatches, setExpandedMatches] = useState({});

    useEffect(() => {
        if (props?.id) {
            getMatchList(props.id);
        }
    }, [props?.id])

    const getMatchList = (matchId) => {
        setLoading(true);
        api.getMatchList(matchId).then(res => {
            const {data, status} = res;
            if (status === 200) {
                const groupData = groupDataByLeague(data);
                setContentsList(groupData);
            }
            else {
                window.alert("API 서버 통신 에러");
            }
        })
        .catch(ex => {
            window.alert(ex.message);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const groupDataByLeague = (data) => {
        return data.reduce((acc, item) => {
            const key = `${item.league_id}_${item.league_name}`;
            if (!acc[key]) {
                acc[key] = {
                    league_id: item.league_id,
                    league_name: item.league_name,
                    league_image: item.league_image,
                    cc_kr: item.cc_kr,
                    cc_image: item.cc_image,
                    matches: []
                }
            }
            acc[key].matches.push(item);
            return acc;
        }, {});
    };

    const handleOddsClick = (match, odds, type) => {
        if (!odds) return;

        let oddValue;
        let oddName;

        switch(type) {
            case 'home':
                oddValue = odds.oddsHomeValue;
                oddName = odds.oddsHomeName;
                break;
            case 'draw':
                oddValue = odds.oddsDrawValue;
                oddName = odds.oddsDrawName;
                break;
            case 'away':
                oddValue = odds.oddsAwayValue;
                oddName = odds.oddsAwayName;
                break;
            default:
                return;
        }

        const bettingData = {
            id: match.id,
            league_name: match.league_name,
            sport: match.sport,
            market_id: odds.marketId,
            market_name: odds.marketName,
            odd_name: oddName,
            odd_value: parseFloat(oddValue),
            odds_base: odds.oddsBase || "0"
        };

        onCart(bettingData);
    };

    const toggleMatchExpand = (matchId) => {
        setExpandedMatches(prev => ({
            ...prev,
            [matchId]: !prev[matchId]
        }));
    };

    const renderOddsRow = (match, odds) => {
        return (
            <div className="grid grid-cols-[120px_100px_1fr_80px_1fr_120px] items-center gap-2 p-2 bg-gray-50">
                <span className="text-sm"></span>
                <span className="text-sm">{odds.marketName} {odds.oddsBase && `(${odds.oddsBase})`}</span>
                
                {/* Home 배당 */}
                <div 
                    className="flex flex-1 flex-row p-2 text-center hover:bg-gray-100 border rounded items-center justify-between cursor-pointer"
                    onClick={() => handleOddsClick(match, odds, 'home')}
                >
                    <div className="text-sm truncate">{odds.oddsHomeName}</div>
                    <div className="font-bold">{odds.oddsHomeValue}</div>
                </div>

                {/* Draw 배당 */}
                <div className="w-20 p-2 text-center border rounded">
                    {odds.oddsDrawValue ? (
                        <div 
                            className="hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleOddsClick(match, odds, 'draw')}
                        >
                            <div className="font-bold">{odds.oddsDrawValue}</div>
                        </div>
                    ) : (
                        <div className="text-gray-500">VS</div>
                    )}
                </div>

                {/* Away 배당 */}
                <div 
                    className="flex flex-1 flex-row p-2 text-center hover:bg-gray-100 border rounded items-center justify-between cursor-pointer"
                    onClick={() => handleOddsClick(match, odds, 'away')}
                >
                    <div className="text-sm truncate">{odds.oddsAwayName}</div>
                    <div className="font-bold">{odds.oddsAwayValue}</div>
                </div>

                <div></div>
            </div>
        );
    };

    return (
        <div className="flex flex-col h-full bg-white">
            {/* 상단 선택 영역 */}
            <div className="flex justify-between p-4 border-b">
                <div className="flex items-center gap-2">
                    <span className="flex items-center gap-1">
                        {props?.name === '축구' && '⚽'}
                        {props?.name === '농구' && '🏀'}
                        {props?.name === '야구' && '⚾'}
                        <span className="font-bold">{props?.name}</span>
                    </span>
                </div>
            </div>

            {loading && <Loading />}
            
            <div className="flex flex-1 flex-col overflow-y-auto min-h-[calc(100vh-200px)]">
                {Object.keys(contentsList).length > 0 ? (
                    Object.entries(contentsList).map(([key, league]) => (
                        <div key={key} className="border-b">
                            {/* 리그 헤더 */}
                            <div className="flex items-center gap-2 bg-[#4171B8] p-3 text-white">
                                <img src={league.cc_image} alt="" className="w-5 h-5"/>
                                <span className="font-bold">{league.cc_kr} - {league.league_name}</span>
                            </div>

                            {/* 매치 목록 */}
                            <div>
                                {league.matches.map((match) => (
                                    <div key={match.id}>
                                        {/* 기본 배당 행 */}
                                        <div className="grid grid-cols-[120px_100px_1fr_80px_1fr_120px] items-center gap-2 p-2 hover:bg-gray-50">
                                            <span className="text-sm">{match.time.split(' ')[1]}</span>
                                            <span className="text-sm">{match.oddsList?.[0]?.marketName}</span>
                                            
                                            {/* Home 배당 */}
                                            <div 
                                                className="flex flex-1 flex-row p-2 text-center hover:bg-gray-100 border rounded items-center justify-between cursor-pointer"
                                                onClick={() => handleOddsClick(match, match.oddsList[0], 'home')}
                                            >
                                                <div className="text-sm truncate">{match.home_name}</div>
                                                <div className="font-bold">{match.oddsList?.[0]?.oddsHomeValue}</div>
                                            </div>

                                            {/* Draw 배당 */}
                                            <div className="w-20 p-2 text-center border rounded">
                                                {match.oddsList?.[0]?.oddsDrawValue ? (
                                                    <div 
                                                        className="hover:bg-gray-100 cursor-pointer"
                                                        onClick={() => handleOddsClick(match, match.oddsList[0], 'draw')}
                                                    >
                                                        <div className="font-bold">{match.oddsList?.[0]?.oddsDrawValue}</div>
                                                    </div>
                                                ) : (
                                                    <div className="text-gray-500">VS</div>
                                                )}
                                            </div>

                                            {/* Away 배당 */}
                                            <div 
                                                className="flex flex-1 flex-row p-2 text-center hover:bg-gray-100 border rounded items-center justify-between cursor-pointer"
                                                onClick={() => handleOddsClick(match, match.oddsList[0], 'away')}
                                            >
                                                <div className="text-sm truncate">{match.away_name}</div>
                                                <div className="font-bold">{match.oddsList?.[0]?.oddsAwayValue}</div>
                                            </div>

                                            {/* 더보기 버튼 */}
                                            {(match.handyOddsList?.length > 0 || match.unoverOddsList?.length > 0) && (
                                                <button 
                                                    className="px-3 py-1 bg-gray-800 text-white rounded text-sm"
                                                    onClick={() => toggleMatchExpand(match.id)}
                                                >
                                                    {expandedMatches[match.id] ? '접기' : '+더보기'}
                                                </button>
                                            )}
                                        </div>

                                        {/* 확장된 배당 정보 */}
                                        {expandedMatches[match.id] && (
                                            <div className="border-t border-gray-100">
                                                {match.handyOddsList?.map((odds, index) => renderOddsRow(match, odds))}
                                                {match.unoverOddsList?.map((odds, index) => renderOddsRow(match, odds))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="flex flex-1 items-center justify-center">
                        <div className="text-center p-8 bg-gray-50 rounded-lg">
                            <p className="text-gray-500 font-medium">진행중인 경기가 없습니다.</p>
                            <p className="text-gray-400 text-sm mt-1">다른 종목을 선택해주세요.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default FreeMatch;