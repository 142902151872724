import {useEffect, useState} from "react";
import {matches} from "../../assets/data/match";

const Left = ({onSelectLeague}) => {
    const [selectedMatch, setSelectedMatch] = useState(matches.data[0]);

    useEffect(() => {
        // 초기 로딩시 첫번째 종목 선택
        onMatch(matches.data[0]);
    }, []);

    const onMatch = (match) => {
        setSelectedMatch(match);
        onSelectLeague({
            id: match.id,
            name: match.name
        });
    }

    return (
        <div className={"flex flex-row p-2 bg-[#2a3340] drop-shadow-xl gap-4"}>
            {matches.data.map((match, idx) => {
                const isSelected = match.id === selectedMatch.id;
                return (
                    <div key={String(idx)}
                         className={`cursor-pointer px-2 ${isSelected ? "rounded bg-blue-500" : ""}`}
                         onClick={() => onMatch(match)}>
                        <span className={"text-white font-bold"}>{match.name}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default Left;