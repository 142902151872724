import {useEffect, useState} from "react";
import * as Global from "../biz/Global"
import Main from "./Main";
import Betting from "../betting/Betting";
import UserDeposit from "../user/deposit/UserDeposit";
import UserWithdraw from "../user/deposit/UserWithdraw";
import Customer from "../customer/Customer";
import BettingGuide from "../betting/BettingGuide";
import MyBettingList from "../user/betting/MyBettingList";
import Notice from "../customer/Notice";

const Contents = (props) => {
    const [menuIdx, setMenuIdx] = useState(0)

    useEffect(() => {
        console.log("### Contents - props :: ", props)
        setMenuIdx(props.menuIdx)
    }, [props])

    const CurrentView = () => {
        switch (menuIdx) {
            case Global.MENU_BETTING: // 배팅
                return <Betting />
            case Global.MENU_CUSTOMER: // 고객센터
                return <Customer />
            case Global.MENU_BOARD: // 게시판
                return
            case Global.MENU_BETTING_HISTORY: //  배팅내역
                return <MyBettingList />
            case Global.MENU_BETTING_RULE: //  배팅규정
                return <BettingGuide />
            case Global.MENU_NOTICE: //  공지사항
                return <Notice />
            case Global.MENU_CHARGE: // 충전
                return <UserDeposit />
            case Global.MENU_WITHDRAW:  // 환전
                return <UserWithdraw />
            default:
                return <Main onMenu={props.onMenu} />
        }
    }

    return (
        <div className={"min-w-[1140px] flex flex-col"}>
            <CurrentView />
        </div>
    )
}

export default Contents