import axios from "axios";

export const host = "http://api.popobu.com:18080/game"
export const apiHost = "http://api.popobu.com:18080"
// export const apiHost = "http://localhost:18080";

const proxy = "https://ziha-proxy-bef22eb1e8af.herokuapp.com/";

const isDebug = false


function post(request, body) {
    let http = proxy + request
    if (isDebug) {
        http = request
    }

    return axios.post(http, body);
}

function get(request, body) {
    let http = proxy + request
    if (isDebug) {
        http = request
    }

    return axios.get(http, {
        params: body,
    })
}

export function put(request, body) {
    let http = proxy + request
    if (isDebug) {
        http = request
    }

    return axios.put(http, body);
}

// export function getMatchList(match) {
//     const request = host + "/" + match + "/list?token=HJ4wIB6sTp52uULLYLDzBRs0VsbAlVXR";
//     return get(request);
// }

// export function getOddsList(match) {
//     const request = host + "/" + match + "/multi?token=HJ4wIB6sTp52uULLYLDzBRs0VsbAlVXR";
//     return get(request);
// }

export function getMatchList(match) {
    const request = apiHost + "/api/v1/sports/list/" + match
    return get(request)
}

export function login(info) {
    // const request = host2 + "/login/";
    const request = apiHost + "/api/v1/login/"
    return post(request, info);
}

export function getGUser(userId) {
    const request = apiHost + "/api/v1/user/" + userId;
    return get(request);
}

export function putGUser(gUser) {
    const request = apiHost + "/api/v1/user/";
    return put(request, gUser);
}

/**
 * 입금신청
 */
export function getDepositList(params) {
    const request = apiHost + "/api/v1/user/cash/deposit/request/list"
    return post(request, params)
}

export function putDeposit(deposit) {
    const request = apiHost + "/api/v1/user/cash/deposit";
    return put(request, deposit)
}

/**
 * 출금신청
 */
export function getWithdrawList(params) {
    const request = apiHost + "/api/v1/user/cash/withdraw/request/list"
    return post(request, params)
}

export function putWithdraw(withdraw) {
    const request = apiHost + "/api/v1/user/cash/withdraw";
    return put(request, withdraw)
}

/**
 * 경기목록 (now)
 */
export function getSportsNow() {
    const request = apiHost + "/api/v1/sports/now";
    return get(request)
}

/**
 * 배팅
 */
export function putBetting(betting) {
    const request = apiHost + "/api/v1/betting/";
    return put(request, betting)
}

export function getUserBettingList(userId) {
    const request = apiHost + "/api/v1/betting/" + userId + "/list"
    return get(request)
}

/**
 * 포인트전환
 */
export function requestExchangePoint(exchange) {
    const request = apiHost + "/api/v1/user/point/exchange"
    return post(request, exchange)
}

export function getBoardList(boardId) {
    const request = apiHost + "/api/v1/board/" + boardId
    return get(request)
}

export function getSportsBaseList() {
    const request = apiHost + "/api/v1/sports/base/list";
    return get(request)
}

// 점검 여부
export function getSiteInspection(siteId) {
    const request = apiHost + "/api/v1/config/site/inspection/" + siteId
    return get(request)
}